
import React from 'react';
import youtubeThumbnail from 'youtube-thumbnail-downloader-hd';
import { saveAs } from 'file-saver'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PropTypes from 'prop-types';
import '../pages/page.css';

class YoutubeDownloader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: "", thumbnail: {} };
    }

    handleDomain = (e) => {
        console.log(e.target.value);
        this.setState({ value: e.target.value });
        var thumbnail = youtubeThumbnail(e.target.value);
        console.log(thumbnail)
        this.setState({ thumbnail: thumbnail });
    }


    downloadImage=(e,val)=>{
        saveAs(val,"youtube.jpg")
        console.log(e.target.href,val)
        console.log(e.target.value);
    }

    getThumb = (ev) => {
        var thumbnail = youtubeThumbnail(this.state.value);
        console.log(thumbnail);
    }

    render() {
        return (
            <Layout>
                <SEO  title="Youtube Thumbnail Image Downloader | Vimeo Thumbnail Downloader"  description="Download and Grab youtube thumbnail Images and vimeo videos of all quality. This app lets you to download HD and Hig Resolution thumbnail images for free.
"  />
            <div>

                <div class="container mt-4">
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-9">
                            <div class="card p-4 mt-3">
                                <h1 class="heading mt-5 text-center">Youtube Thumbnail Image Downloader </h1>
                                <p class="text-center">Download youtube and vimeo thumbnail images online of all quality for free . Online youtube thumbnail downloader lets you download thumbnails of all quality. Just paste the URL of the thumbnail video in the below input and you will get images automatically . Download HD Quality thumbnails , Ultra HD quality images Online
                                </p>
                                <div class="d-flex justify-content-center px-5">
                                    <div class="search"> <input type="text" onChange={this.handleDomain} class="search-input" placeholder="Enter Youtube link to Download thumbnail image" name="" />  </div>
                                </div>

                                <div class="container">
                                    <div class="row p-3">
                                        {this.state.thumbnail.default != undefined ?
                                            <div class="col-sm">
                                                <div class="text-center">
                                                    <h4>Default Image ({this.state.thumbnail.default.width}x{this.state.thumbnail.default.height})</h4>
                                                    <button type="button" className="btn btn-outline-primary" href={this.state.thumbnail.default.url} onClick={e => this.downloadImage(e,this.state.thumbnail.default.url)}>Download Default Image </button>
                                                </div>
                                                <div class="text-center">
                                                    <img width="120" height="90" src={this.state.thumbnail.default.url}  class="rounded" alt="..." />
                                                </div>
                                            </div> : ""}
                                    </div>
                                </div>

                                <div className="container">
                                    <div class="row fluid p-3">
                                        {this.state.thumbnail.sdddefautl != undefined ?
                                            <div class="col-sm">
                                                <div class="text-center">
                                                    <h4>SDD Image ({this.state.thumbnail.sdddefautl.width}x{this.state.thumbnail.sdddefautl.height})</h4>
                                                    <button type="button" className="btn btn-outline-primary" href={this.state.thumbnail.sdddefautl.url} onClick={e => this.downloadImage(e,this.state.thumbnail.sdddefautl.url)} >Download Medium Image </button>
                                                </div>
                                                <div class="text-center">
                                                    <img width="620" height="500" src={this.state.thumbnail.sdddefautl.url} class="rounded" alt="..." />
                                                </div>
                                            </div> : ""}
                                    </div>
                                </div>

                                <div className="container">
                                    <div class="row fluid p-3">
                                        {this.state.thumbnail.medium != undefined ?
                                            <div class="col-sm">
                                                <div class="text-center">
                                                    <h4>Medium Image ({this.state.thumbnail.medium.width}x{this.state.thumbnail.medium.height})</h4>
                                                    <button type="button" className="btn btn-outline-primary" href={this.state.thumbnail.medium.url} onClick={e => this.downloadImage(e,this.state.thumbnail.medium.url)}  >Download Medium Image </button>
                                                </div>
                                                <div class="text-center">
                                                    <img src={this.state.thumbnail.medium.url} class="rounded" alt="..." />
                                                </div>
                                            </div> : ""}
                                    </div>
                                </div>


                                <div className="container">
                                    <div class="row p-3">
                                        {this.state.thumbnail.high != undefined ?

                                            <div class="col-sm">
                                                <div class="text-center">
                                                    <h4>High Res Image ({this.state.thumbnail.high.width}x{this.state.thumbnail.high.height})</h4>
                                                    <button type="button" className="btn btn-outline-primary"  href={this.state.thumbnail.high.url} onClick={e => this.downloadImage(e,this.state.thumbnail.high.url)}   >Download HD Image </button>
                                                </div>
                                                <div class="text-center">
                                                    <img width="620" height="500" src={this.state.thumbnail.high.url} class="rounded" alt="..." />
                                                </div>

                                            </div> : ""}


                                    </div>
                                </div>


                                <div className="container">
                                    <div class="row p-3">
                                        {this.state.thumbnail.highMaxRes != undefined ?

                                            <div class="col-sm">
                                                <div class="text-center">
                                                    <h4>High Res Image ({this.state.thumbnail.highMaxRes.width}x{this.state.thumbnail.highMaxRes.height})</h4>
                                                    <button type="button" className="btn btn-outline-primary" href={this.state.thumbnail.highMaxRes.url} onClick={e => this.downloadImage(e,this.state.thumbnail.highMaxRes.url)} >Download High Res Image </button>
                                                </div>
                                                <div class="text-center">
                                                    <img width="620" height="500" src={this.state.thumbnail.highMaxRes.url} class="rounded" alt="..." />
                                                </div>

                                            </div> : ""}


                                    </div>
                                </div>

{/*                                



                                <div class="row mt-4 g-1 px-4 mb-5">
                                    <div class="col-md-2">
                                        <div class="card-inner p-3 d-flex flex-column align-items-center"> <img src={this.state.thumbnail.default != undefined ? this.state.thumbnail.default.url : ""} width="50" />
                                            <div class="text-center mg-text"> <span class="mg-text"><button type="button" class="btn btn-outline-primary">Download {this.state.thumbnail.default != undefined ? this.state.thumbnail.default.width : ""}*{this.state.thumbnail.default != undefined ? this.state.thumbnail.default.height : ""}</button>
                                            </span> </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="card-inner p-3 d-flex flex-column align-items-center"> <img src="https://i.imgur.com/ueLEPGq.png" width="50" />
                                            <div class="text-center mg-text"> <span class="mg-text">Payments</span> </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="card-inner p-3 d-flex flex-column align-items-center"> <img src="https://i.imgur.com/tmqv0Eq.png" width="50" />
                                            <div class="text-center mg-text"> <span class="mg-text">Delivery</span> </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="card-inner p-3 d-flex flex-column align-items-center"> <img src="https://i.imgur.com/D0Sm15i.png" width="50" />
                                            <div class="text-center mg-text"> <span class="mg-text">Product</span> </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="card-inner p-3 d-flex flex-column align-items-center"> <img src="https://i.imgur.com/Z7BJ8Po.png" width="50" />
                                            <div class="text-center mg-text"> <span class="mg-text">Return</span> </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="card-inner p-3 d-flex flex-column align-items-center"> <img src="https://i.imgur.com/YLsQrn3.png" width="50" />
                                            <div class="text-center mg-text"> <span class="mg-text">Guarantee</span> </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <h2>What are Youtube Thumbnail Image</h2>
                <p>As a YouTube user, you may have noticed that the thumbnail image is an important element in determining whether or not someone clicks on your video. In this blog post, we'll take a look at why thumbnail images are so important, and how you can make sure that your images are making the best impression on your potential viewers.</p>


<p>The thumbnail image is the first thing that someone sees when they come across your video in their YouTube feed. And as we all know, first impressions are important. If your thumbnail image is eye-catching and intrigues the viewer, they're much more likely to click on your video and watch it. On the other hand, if your thumbnail image is boring or unappealing, the viewer is much less likely to watch your video.</p>

<p>So, how can you make sure that your thumbnail images are making the best impression? First, make sure that your images are high quality and clear. Blurry or low-quality images will not only turn viewers away, but they'll also make your video look less professional. Second, make sure that your images are relevant to your video's content. If your video is about dogs, your thumbnail image should be of a dog (or at least have something to do with dogs).
</p>
            </div>

<h2>Youtube Thumbnail Downloader - what is it and why you need it</h2>
<p>
Youtube Thumbnail downloader is used to download images from YouTube thumbnail. You can choose a link to download image from video .
YouTube Thumbnail downloader can be used for downloading a custom image on image with already present in YouTube. Download the YouTube images in different resolution such  high, medium ,low .
 
</p>

<h2>How to Use Our youtube Thumbnail Downloader</h2>

<p>

If you've ever wanted to download a thumbnail image from a YouTube video but didn't know how, our YouTube thumbnail downloader is here to help. Simply enter the YouTube URL of the video you want to download the thumbnail from and click the "Download" button.

Our thumbnail downloader will then fetch the thumbnail image from the video and provide you with a link to download it. You can then use this image as you please. Whether you want to use it as a featured image on your website or blog, or simply as a way to remember a particular video, our thumbnail downloader is a quick and easy way to get the job done.

There are a few benefits to using the YouTube Thumbnail Downloader. One benefit is that it can help you find videos that you're interested in. Another benefit is that it can help you save time by allowing you to download thumbnails in bulk.
</p>


            <h2>Download Vimeo Thumbnail Image using Our Vimeo Thumbnail Downloader</h2>
            <p>
There are a few things to consider when creating a thumbnail for your Vimeo video. The first is the size of the thumbnail. Vimeo recommends that you use a thumbnail that is 1280x720 pixels. The second is the file type of the thumbnail. Vimeo supports both JPEG and PNG files. The third is the file name of the thumbnail. Vimeo will automatically generate a file name for your thumbnail, but you can also choose to name it yourself.

When creating a thumbnail, keep in mind that it should be a representation of your video. Choose an image that will be recognizable and attract viewers. Additionally, make sure that the thumbnail image is clear and legible. Blurry or pixelated images will not be as effective.

Once you've created your thumbnail, you can upload it to Vimeo. To do this, go to the video page and click the "Edit" button. Under the "Basic Settings" section, you will see an option to upload a thumbnail. Click the "Choose File" button and select your thumbnail image. Once you've selected the image, click the "Upload" button.

Your thumbnail image will now be displayed on your video page. When viewers hover over the thumbnail, they will see a preview</p>

            </Layout>
        );
    }
}

YoutubeDownloader.propTypes = {};

export default YoutubeDownloader;
